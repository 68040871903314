
//= include jquery/dist/jquery.js
//= include bootstrap/dist/js/bootstrap.bundle.min.js
//= include jquery.maskedinput/src/jquery.maskedinput.js
//= include slick-carousel/slick/slick.min.js
//= include popmotion/dist/popmotion.min.js
//= include vendor/fm.revealator.jquery.js

//= include jquery-form-validator/form-validator/jquery.form-validator.js
//= include jquery-form-validator/form-validator/html5.js
//= include jquery-form-validator/form-validator/lang/ru.js

//= include sticky-sidebar/dist/jquery.sticky-sidebar.min.js

//= include typewriter-effect/dist/core.js

